import { createAsyncThunk } from '@reduxjs/toolkit';
import { appAxios } from '../axiosInstance';

export const authentication = createAsyncThunk<any, any, any>('auth/fetchAccess', async data => {
  try {
    const response = await appAxios.post('/authentication', data);
    return response.data;
  } catch (error: any) {
    console.log(error);
    return error;
  }
});

export const fetchToken = createAsyncThunk<any, any>('auth/fetchToken', async data => {
  try {
    const response = await appAxios.post('/token', data);
    return response.data;
  } catch (error: any) {
    console.log(error);
    return error;
  }
});

export const updateToken = createAsyncThunk<any>('auth/updateToken', async () => {
  try {
    const response = await appAxios.post('/secured/token/refresh', {
      token: localStorage.getItem('refreshToken'),
    });
    return response.data;
  } catch (error: any) {
    console.log(error);
  }
});

export const getUserProfile = createAsyncThunk<any, void>('auth/getUserProfile', async () => {
  try {
    const userId = localStorage.getItem('userId');
    const response = await appAxios.get('/secured/users/' + userId);
    return response.data;
  } catch (error: any) {
    console.log(error);
  }
});

export const logout = createAsyncThunk('auth/logout', async _ => {
  try {
    const response = await appAxios.post('/secured/users/logout');
    return response.data;
  } catch (error: any) {
    if (window) {
      window.location.reload();
    }
    console.log(error);
  }
});
