import cn from 'classnames';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { authentication, fetchToken } from '../../redux/auth/authThunk';
import { FormModel, FormErrorsModel } from '../../models/Login.models';
import { useAppDispatch } from '../../redux/hooks';

const Login = () => {
  const dispatch = useAppDispatch();
  const [form, setForm] = useState<FormModel>({
    username: '',
    password: '',
    gReCaptchaResponse: 'test',
  });
  const [formErrors, setFormErrors] = useState<FormErrorsModel>({
    username: false,
    password: false,
  });

  const login = async () => {
    if (!form.username) {
      setFormErrors((prevErrors: any) => {
        return { ...prevErrors, username: 'Email is required.' };
      });

      return;
    }

    if (!form.password) {
      setFormErrors((prevErrors: any) => {
        return { ...prevErrors, password: 'Password is required.' };
      });

      return;
    }
    if (form.username && form.password) {
      setFormErrors(() => {
        return { username: false, password: false };
      });

      await dispatch(authentication(form)).then((res: any) => {
        if (res?.payload?.response?.data?.error?.email) {
          toast.error(res?.payload?.response?.data?.error?.email);
          return;
        } else if (res?.payload?.response?.data?.error) {
          toast.error(res?.payload?.response?.data?.error);
          return;
        } else {
          getUserToken();
        }
      });
    }
  };

  const getUserToken = async () => {
    await dispatch(
      fetchToken({
        username: localStorage.getItem('username'),
        accessToken: localStorage.getItem('accessToken'),
      })
    ).then(() => {
      window.location.reload();
    });
  };

  const onFieldChange = (event: any) => {
    const { name, value } = event.target;

    setForm((prevForm: any) => {
      return { ...prevForm, [name]: value };
    });

    if (value) {
      setFormErrors((prevErrors: any) => {
        return { ...prevErrors, [name]: false };
      });
    }
  };

  React.useEffect(() => {
    const handleGlobalKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        const LoginBtn = document.querySelector('.login-form-button') as HTMLButtonElement;
        LoginBtn?.click();
        if (form.username && form.password) {
          login();
        }
      }
    };
    document.body.addEventListener('keydown', handleGlobalKeyPress);

    return () => {
      document.body.removeEventListener('keydown', handleGlobalKeyPress);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <div className='login'>
      <div className='login-form'>
        <h4 className='login-form-title'>Login to account</h4>
        <div className='login-form-field'>
          <input
            type='text'
            placeholder='Email'
            name='username'
            className={cn({
              error: formErrors.username,
            })}
            onChange={onFieldChange}
          />
          {formErrors.username && <small className='login-form-field-error'>{formErrors.username}</small>}
        </div>
        <div className='login-form-field'>
          <input
            type='password'
            placeholder='Password'
            name='password'
            className={cn({
              error: formErrors.password,
            })}
            onChange={onFieldChange}
          />
          {formErrors.password && <small className='login-form-field-error'>{formErrors.password}</small>}
        </div>
        <button className='login-form-button' onClick={() => login()}>
          Login
        </button>
      </div>
    </div>
  );
};

export default Login;
