import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';
import { useAppSelector } from '../../redux/hooks';

const ProfileCard = () => {
  const { profile } = useAppSelector(state => state.users);

  if (!profile) return <></>;

  return (
    <Card className='profile-card'>
      <div className='avatar'>
        <FontAwesomeIcon icon={faUser} size='2x' />
      </div>
      <div className='username'>{profile.firstName + ' ' + profile.lastName}</div>
      <div className='work-position'>
        {profile.position} at {profile.company.name}
      </div>
      {profile.roles[0].toLowerCase() !== 'employee' && (
        <div>
          System Role: <b>{profile.roles[0].toUpperCase()}</b>
        </div>
      )}
    </Card>
  );
};

export default ProfileCard;
