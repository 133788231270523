import { ToastContainer } from "react-toastify";
import Hero from "../Hero";
import Sidebar from "../Sidebar";
import Footer from "../Footer/Footer";

export const Layout = (props: any) => {
  return (
    <>
      <div className="layout">
        <div className="layout-left">
          <Sidebar />
        </div>
        <div className="layout-center">
          <Hero />
          {props?.children}
        </div>
      </div>

      <Footer />

      <ToastContainer
        hideProgressBar={true}
        style={{ margin: "0", padding: "0", width: "400px" }}
      />
    </>
  );
};

export default Layout;
