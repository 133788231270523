export const formatDate = (date = new Date(), format: string = 'YYYY-MM-DD') => {
  const year = date.toLocaleString('default', { year: 'numeric' });
  const month = date.toLocaleString('default', {
    month: '2-digit',
  });
  const day = date.toLocaleString('default', { day: '2-digit' });

  switch (format) {
    case 'MM-DD-YYYY':
      return [month, day, year].join('-');
    case 'DD-MM-YYYY':
      return [day, month, year].join('-');
    case 'Mon-DD-YYYY':
      return `${date.toLocaleString('default', { month: 'short' })} ${day}, ${year}`;
    default:
      return [year, month, day].join('-');
  }
};

export const checkForUserRoles = (user: any, roles: string[]) => {
  let userHasAnyRole = false;

  if (user) {
    roles.forEach((role: string) => {
      if (user.roles.includes(role)) {
        userHasAnyRole = true;
      }
    });
  }

  return userHasAnyRole;
};
