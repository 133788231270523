import { useEffect, useState } from 'react';
import Layout from './components/Layout';
import { useAppDispatch } from './redux/hooks';
import { getUserProfile } from './redux/auth/authThunk';
import './assets/styles/global.scss';
import AuthNavigation from './navigation/AuthNavigation';
import UnauthNavigation from './navigation/UnauthNavigation';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkAuthUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkAuthUser = async () => {
    if (
      localStorage.getItem('bearerToken') === null ||
      localStorage.getItem('bearerToken') === 'undefined' ||
      localStorage.getItem('refreshToken') === null ||
      localStorage.getItem('refreshToken') === 'undefined'
    ) {
      localStorage.removeItem('bearerToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userId');
    }

    if (localStorage.getItem('userId') && localStorage.getItem('accessToken')) {
      await dispatch(getUserProfile());
    }

    setLoading(false);
  };

  if (loading) {
    return <></>;
  }

  return (
    <div className='App'>
      <BrowserRouter>
        {localStorage.getItem('userId') ? (
          <Layout>
            <AuthNavigation />
          </Layout>
        ) : (
          <UnauthNavigation />
        )}
        <ToastContainer hideProgressBar={true} style={{ margin: '0', padding: '0', width: '400px' }} />
      </BrowserRouter>
    </div>
  );
}

export default App;
