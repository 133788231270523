import { createSlice } from '@reduxjs/toolkit';
import { getSubscriptions, createSubscription, deleteSubscription } from './subscriptionsThunk';

type SubscriptionsState = {
  subscriptions: null | any;
  loading: boolean;
  error: any;
};

const initialState: SubscriptionsState = {
  subscriptions: [],
  loading: true,
  error: null,
};

const subscriptionSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder

      .addCase(getSubscriptions.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSubscriptions.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.subscriptions = payload;
      })
      .addCase(getSubscriptions.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(createSubscription.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createSubscription.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(createSubscription.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(deleteSubscription.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSubscription.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteSubscription.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { clearError } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
