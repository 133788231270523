import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logout } from '../../redux/auth/authThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { formatDate } from '../../utils';
import { updateDate } from '../../redux/reports/reportsThunk';
import { useState } from 'react';

const Hero = () => {
  const dispatch = useAppDispatch();
  const { auth } = useAppSelector(state => state.auth);
  const [heroDate, setHeroDate] = useState(formatDate(new Date()));

  const onLogout = () => {
    dispatch(logout()).then(() => {
      window.location.reload();
    });
  };

  const onDateChanged = (e: any) => {
    const { value } = e.target;

    dispatch(updateDate(value));
    displayDatePicker();
    setHeroDate(() => {
      return value;
    });
  };

  const displayDatePicker = () => {
    const datePicker: any = document.getElementById('hero-bar-input');

    if (datePicker) {
      datePicker.showPicker();
    }
  };

  return (
    <div className='hero'>
      <div className='hero-bar'>
        <div className='hero-bar-picker' onClick={displayDatePicker}>
          {heroDate === formatDate(new Date()) ? 'Today' : `${formatDate(new Date(heroDate), 'Mon-DD-YYYY')}`}
        </div>
        <input
          type='date'
          id='hero-bar-input'
          defaultValue={heroDate}
          onChange={onDateChanged}
          max={formatDate(new Date())}
        />
      </div>
      <div className='user-profile'>
        <p className='username'>
          {auth?.firstName + ' ' + auth?.lastName} <FontAwesomeIcon icon={faChevronDown} />{' '}
        </p>
        <ul className='sub-menu'>
          <li className='sub-menu-item'>
            <NavLink to='/profile'>
              <p>Profile</p>
            </NavLink>
          </li>
        </ul>
      </div>
      <button onClick={onLogout}>Logout</button>
    </div>
  );
};

export default Hero;
