import { configureStore } from '@reduxjs/toolkit';
import authSlice from './auth/auth-slice';
import reportsSlice from './reports/reports-slice';
import usersSlice from './users/users-slice';
import subscriptionSlice from './subscriptions/subscriptions-slice';

export const store = configureStore({
  reducer: { auth: authSlice, reports: reportsSlice, users: usersSlice, subscriptions: subscriptionSlice },
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
