import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ProfileInfo from '../../components/ProfileInfo';
import ChangePassword from '../../components/ChangePassword';

const Profile = () => {
  return (
    <div className='profile-wrapper'>
      <Tabs defaultActiveKey='profile-info' id='profile-tabs'>
        <Tab eventKey='profile-info' title='Profile Info'>
          <ProfileInfo />
        </Tab>
        <Tab eventKey='profile' title='Change Password'>
          <ChangePassword />
        </Tab>
      </Tabs>
    </div>
  );
};

export default Profile;
