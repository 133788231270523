import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getUsersReports, sendReport } from '../../redux/reports/reportsThunk';
import {
  createUserSubscriptions,
  deleteUserSubscriptions,
  getSelectedTechLead,
  getUserSubscriptions,
} from '../../redux/users/usersThunk';
import { checkForUserRoles } from '../../utils';
import Report from '../Report';
import Spinner from '../Spinner';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '40%',
    maxWidth: '60%',
  },
};

const Reports = () => {
  const dispatch = useAppDispatch();
  const { usersReports, date } = useAppSelector(state => state.reports);
  const { selectedTechLead, techLeadSubscriptions } = useAppSelector(state => state.users);
  const { auth } = useAppSelector(state => state.auth);
  const [reports, setReports] = useState(usersReports);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedReportId, setSelectedReportId] = useState('-1');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(getUserSubscriptions());

    if (checkForUserRoles(auth, ['TECH_LEAD'])) {
      dispatch(
        getSelectedTechLead({
          id: auth.id,
        })
      );
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getReports(date);
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 400);

    // eslint-disable-next-line
  }, [date]);

  useEffect(() => {
    setReports(() => {
      return [...usersReports];
    });
  }, [usersReports]);

  const getReports = (date: string) => {
    dispatch(
      getUsersReports({
        date,
      })
    );
  };

  const getReportInfoByUserId = (userId: string, fieldToReturn: string) => {
    const report = reports?.filter((report: any) => report.userId === userId)[0];

    if (report) {
      return report[fieldToReturn];
    }

    return null;
  };

  const getReportInfoByReportId = (reportId: string, fieldToReturn: string) => {
    const report = reports?.filter((report: any) => report.id === reportId)[0];

    if (report) {
      return report[fieldToReturn];
    }

    return null;
  };

  const changeSubscriptions = (event: any, employeeId: string, subscription: any) => {
    const { checked } = event.target;

    if (checked) {
      dispatch(
        createUserSubscriptions({
          usersId: employeeId,
        })
      );
    } else {
      dispatch(deleteUserSubscriptions({ id: subscription[0]?.id, employeeId: employeeId }));
    }
  };

  const submitUserReport = (employeeId: string) => {
    dispatch(
      sendReport({
        date: date,
        userId: employeeId,
      })
    ).then(() => {
      getReports(date);
    });
  };

  const viewReportDetails = (userId: string) => {
    setShowModal(true);
    setSelectedReportId(userId);
  };

  return (
    <div className='reports-wrapper'>
      {!isLoading ? (
        <>
          {' '}
          <div className='table-header'>
            <div className='id'>
              <b>#</b>
            </div>
            <div className='name'>
              <b>Name</b>
            </div>
            <div className='hours'>
              <b>Worked Hours</b>
            </div>
            <div className='status'>
              <b>Report Status</b>
            </div>
            <div className='submit'>
              <b>Submit</b>
            </div>
            <div className='subscribe'>
              <b>Subscribe</b>
            </div>
            <div className='actions'>
              <b>Report Details</b>
            </div>
          </div>
          <div className='table-body'>
            {checkForUserRoles(auth, ['ADMIN']) ? (
              <>
                {usersReports.map((report: any) => {
                  const status = getReportInfoByReportId(report.id, 'status') || 'IN_PROGRESS';
                  const subscription =
                    techLeadSubscriptions.filter((subscription: any) => subscription.usersId === report.userId) || [];

                  return (
                    <div className='table-body-row' key={report.id}>
                      <div className='table-body-row-item id'>{report.id}</div>
                      <div className='table-body-row-item name'>{report.firstName + ' ' + report.lastName}</div>
                      <div className='table-body-row-item hours'>
                        {getReportInfoByReportId(report.id, 'hoursTracked') || '00:00'}
                      </div>
                      <div className='table-body-row-item status'>
                        <span className={status}>{status}</span>
                      </div>
                      <div className='table-body-row-item submit'>
                        {!status || status === 'IN_PROGRESS' ? (
                          <button onClick={() => submitUserReport(report.userId)}>Submit</button>
                        ) : (
                          <span>-</span>
                        )}
                      </div>
                      <div className='table-body-row-item subscribe'>
                        <input
                          type='checkbox'
                          checked={subscription?.length > 0 ? true : false}
                          onChange={e => changeSubscriptions(e, report.userId, subscription)}
                        />
                      </div>
                      <div className='table-body-row-item actions'>
                        <button onClick={() => viewReportDetails(report.userId)}>View</button>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {selectedTechLead && selectedTechLead.attachedEmpoyees.length > 0 ? (
                  selectedTechLead.attachedEmpoyees.map((employee: any) => {
                    const status = getReportInfoByUserId(employee.id, 'status') || 'IN_PROGRESS';
                    const subscription =
                      techLeadSubscriptions.filter((subscription: any) => subscription.usersId === employee.id) || [];

                    return (
                      <div className='table-body-row' key={employee.id}>
                        <div className='table-body-row-item id'>{employee.id}</div>
                        <div className='table-body-row-item name'>{employee.firstName + ' ' + employee.lastName}</div>
                        <div className='table-body-row-item hours'>
                          {getReportInfoByUserId(employee.id, 'hoursTracked') || '00:00'}
                        </div>
                        <div className='table-body-row-item status'>
                          <span className={status}>{status}</span>
                        </div>
                        <div className='table-body-row-item submit'>
                          {!status || status === 'IN_PROGRESS' ? (
                            <button onClick={() => submitUserReport(employee.id)}>Submit</button>
                          ) : (
                            <span>-</span>
                          )}
                        </div>
                        <div className='table-body-row-item subscribe'>
                          <input
                            type='checkbox'
                            checked={subscription?.length > 0 ? true : false}
                            onChange={e => changeSubscriptions(e, employee.id, subscription)}
                          />
                        </div>
                        <div className='table-body-row-item actions'>
                          <button onClick={() => viewReportDetails(employee.id)}>View</button>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className='not-found'>Data not found.</div>
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <div className='loader'>
          <Spinner />
        </div>
      )}
      <Modal
        isOpen={showModal}
        onRequestClose={() => {
          setShowModal(false);
          setSelectedReportId('-1');
        }}
        style={customStyles}
        ariaHideApp={false}>
        <Report employeeId={selectedReportId} />
      </Modal>
    </div>
  );
};

export default Reports;
